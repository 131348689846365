<packex-modal size="lg" (onDismiss)="dismiss()">
  <h5 class="modal-title">{{ 'ONBOARDING.TITLE' | transloco }}</h5>

  <div class="modal-body">
    <p>{{ 'ONBOARDING.DESCRIPTION' | transloco }}</p>

    <ng-container *ngIf="translateService.getActiveLang() === 'de'; else englishContent">
      <iframe *ngIf="loaded"
              class="cmplazyload"
              src="about: blank"
              data-cmp-hide="1"
              data-cmp-vendor="s77"
              data-cmp-preview="700x430"
              data-cmp-src="https://player.vimeo.com/video/827518480?dnt=1"
              width="700" height="430"
              style="border: 0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </ng-container>

    <ng-template #englishContent>
      <iframe *ngIf="loaded"
              class="cmplazyload"
              src="about: blank"
              data-cmp-hide="1"
              data-cmp-vendor="s77"
              data-cmp-preview="700x430"
              data-cmp-src="https://player.vimeo.com/video/887294436?dnt=1"
              width="700" height="430"
              style="border: 0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </ng-template>

    <packex-list [attributes]="features"></packex-list>

    <p class="text-primary-medium my-4">{{ 'ONBOARDING.ANY_QUESTIONS' | transloco }}</p>

    <div class="d-flex flex-row">
      <div class="d-flex flex-row subtitle-2 justify-content-center me-6">
        <packex-icon icon="mail" color="secondary"></packex-icon>
        <a href="mailto:hello@packex.com" class="ms-2 link">hello&#64;packex.com</a>
      </div>
      <div class="d-flex flex-row subtitle-2 justify-content-center">
        <packex-icon icon="phone" color="secondary"></packex-icon>
        <a href="tel:+49062428370900" class="ms-2 link">+49 (0) 62 42 83 70 - 900</a>
      </div>
    </div>
  </div>

  <packex-button (buttonClick)="dismiss()"
                 class="w-auto">{{ 'ONBOARDING.TRY_NOW' | transloco }}</packex-button>
</packex-modal>
