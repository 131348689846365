import { Injectable } from '@angular/core';
import { ShippingMethod } from '@shared/models/shipping-method';
import { Observable } from 'rxjs';
import { Configuration } from '@shared/models/configuration';
import { ApiService } from '@app/core/services/api.service';
import { PagedResult } from '@shared/models/paged-result';

export type ConfigurationPatch = Partial<Configuration> &
  ShippingMethod & {
    material?: string;
    productOptionIds?: string[];
    configurationAttributeValueIds?: string[];
  };

export interface QuoteEmailBody {
  email: string;
  firstName?: string;
  lastName?: string;
  company?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsService {
  constructor(private apiService: ApiService) {}

  findOne(id: string): Observable<Configuration> {
    return this.apiService.get(`configurations/${id}`);
  }

  findAll(): Observable<PagedResult<Configuration>> {
    return this.apiService.get('configurations');
  }

  public update(
    configurationId: string,
    fieldsToBeUpdated: Partial<ConfigurationPatch>,
  ): Observable<Configuration> {
    return this.apiService.patch(
      `configurations/${configurationId}`,
      fieldsToBeUpdated,
    );
  }

  public create(body: {
    constructionId: string;
    templateId?: string | null;
  }): Observable<Configuration> {
    return this.apiService.post('configurations', body);
  }

  createByInventory(inventoryItemId: string): Observable<Configuration> {
    return this.apiService.post('configurations', {
      inventoryItemId,
    });
  }

  public getDielineUrl(configuration: Configuration): string {
    return (
      `${ApiService.apiUrl}/configurations/${configuration.id}/dieline?t=` +
      new Date().getTime()
    );
  }

  public getQuoteEmail(configurationId: string, body: QuoteEmailBody) {
    return this.apiService.post(
      `configurations/${configurationId}/quote-mail`,
      body,
    );
  }
}
