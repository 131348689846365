import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { WINDOW } from '@shared/window.token';
import { ModalComponent as ModalComponent_1 } from '../modal/modal.component';
import { NgIf } from '@angular/common';
import { ListComponent } from '../ui/list/list.component';
import { IconComponent } from '../icon/icon.component';
import { ButtonComponent } from '../ui/button/button.component';

export const vendorVimeo = 's77';

@Component({
    selector: 'packex-onboarding-dialog',
    templateUrl: './onboarding-dialog.component.html',
    styleUrls: ['./onboarding-dialog.component.scss'],
    imports: [ModalComponent_1, NgIf, ListComponent, IconComponent, ButtonComponent, TranslocoPipe]
})
export class OnboardingDialogComponent
  extends ModalComponent
  implements AfterViewInit
{
  constructor(
    dialogRef: MatDialogRef<OnboardingDialogComponent>,
    public translateService: TranslocoService,
    @Inject(WINDOW) private window: Window,
  ) {
    super(dialogRef);
  }

  features = [
    'ONBOARDING.FEATURES.CART',
    'ONBOARDING.FEATURES.VARIANTS',
    'ONBOARDING.FEATURES.SERVICES',
    'ONBOARDING.FEATURES.ORDER_STATUS',
    'ONBOARDING.FEATURES.NOTIFICATIONS',
    'ONBOARDING.FEATURES.INVENTORY',
  ];

  loaded = false;

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkConsentGiven();

      if (this.window.__cmp) {
        this.window.__cmp('addEventListener', [
          'consentapproved',
          () => {
            this.checkConsentGiven();
          },
          false,
        ]);
      }
    }, 250);

    if (this.window.__cmp) {
      this.window.__cmp('addEventListener', [
        'settings',
        () => {
          this.checkConsentGiven();
        },
        false,
      ]);
    }
  }

  private checkConsentGiven() {
    if (this.window.__cmp) {
      const consentData = this.window.__cmp('getCMPData');

      if (consentData && 'vendorConsents' in consentData) {
        this.loaded = !!(
          vendorVimeo in consentData.vendorConsents &&
          consentData.vendorConsents[vendorVimeo]
        );
      }
    }
  }
}
