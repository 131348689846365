import { Component, Input } from '@angular/core';
import { NgFor } from '@angular/common';
import { IconComponent } from '../../icon/icon.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    imports: [NgFor, IconComponent, TranslocoPipe]
})
export class ListComponent {
  @Input() icon = 'check_circle';
  @Input() attributes: string[] | undefined;
}
